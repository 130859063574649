<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="30%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="addFormRules" ref="addFormRef" label-width="80px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <template>
          <el-upload
            class="avatar-uploader"
            action="/proxy/admin/upload_img"
            name="img"
            :show-file-list="false"
            :on-success="handleSuccess">
            <img v-if="imageUrl" :src="imageUrl" class="cover">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </el-form-item>
      <el-form-item label="排序值" prop="sort">
        <el-input v-model="formData.sort"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'column_add',
  props: [
    'visible', 'id'
  ],
  data () {
    return {
      title: '添加专栏',
      formData: {
        name: '',
        icon: '',
        sort: 0
      },
      imageUrl: '',
      addFormRules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '图标不能为空', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.addFormRef.resetFields()
      this.imageUrl = ''
      this.$emit('closeDialog')
    },
    addConfirm () {
      this.$refs.addFormRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.imageUrl = ''
        if (this.id === 0) {
          this.$emit('add', this.formData)
        } else {
          this.$emit('update', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑专栏'
        this.getGroupInfo(this.id)
      } else {
        this.title = '添加专栏'
      }
    },
    async getGroupInfo (id) {
      const res = await this.$http.get('/admin/column/info/' + id)
      if (res.code === 0) {
        this.formData = {
          name: res.data.name,
          icon: res.data.icon,
          sort: res.data.sort
        }
        this.imageUrl = res.data.icon_url
      }
    },
    handleSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.imageUrl = resp.data.url
        this.formData.icon = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.cover {
  width: 50px;
  display: block;
}
</style>
