<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' subPath='专辑列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="2" v-if="rights.canAdd==1">
          <el-button type="primary" @click="showAddDialog">添加专辑</el-button>
        </el-col>
        <el-col :span="4">
          <el-select v-model="param.group_id" placeholder="请选择分类" filterable>
            <el-option label="请选择分类" value="0"></el-option>
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="param.column_id" placeholder="请选择专栏" filterable>
            <el-option label="请选择专栏" value="0"></el-option>
            <el-option v-for="item in columnList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="param.is_hot" placeholder="是否推荐" filterable>
            <el-option label="全部" value="0"></el-option>
            <el-option label="不推荐" value="1"></el-option>
            <el-option label="推荐" value="2"></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="param.is_publish" placeholder="是否发布" filterable>
            <el-option label="全部" value="0"></el-option>
            <el-option label="未发布" value="1"></el-option>
            <el-option label="已发布" value="2"></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-input placeholder="请输入专辑名称" v-model="param.keywords" clearable @clear="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="param.count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <el-table :data="categoryList" stripe border :cell-style="{ textAlign: 'center' }">
      <el-table-column prop="id" label="ID" align="center" width="50px"></el-table-column>
      <el-table-column prop="name" label="姓名" align="center"></el-table-column>
      <el-table-column prop="pic" label="封面图" align="center">
        <template slot-scope="scope">
          <el-image :src="scope.row.pic" class="list_img"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="group_name" label="分类" align="center"></el-table-column>
      <el-table-column prop="column_name" label="专栏" align="center"></el-table-column>
      <el-table-column prop="content_num" label="内容数量" align="center"></el-table-column>
      <el-table-column prop="play_times" label="浏览次数" align="center"></el-table-column>
      <el-table-column label="是否推荐" align="center">
        <template slot-scope="scope">
          <el-switch :value="scope.row.is_hot==1"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否发布" align="center">
        <template slot-scope="scope">
          <el-switch :value="scope.row.publish_status==2"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序值" align="center"></el-table-column>
      <el-table-column label="内容排序" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.content_sort==1">正序</span>
          <span v-if="scope.row.content_sort==0">倒序</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          {{scope.row.create_time | dateTimeFormat}}
        </template>
      </el-table-column>
      <el-table-column v-if="rights.canUpdate==1 || rights.canDelete==1 || rights.canPublish==1" label="操作" align="center" width="290px">
        <template slot-scope="scope">
          <el-button v-if="rights.canUpdate==1" type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button v-if="rights.canDelete==1" type="danger" icon="el-icon-delete" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
          <el-button v-if="scope.row.publish_status==1 && rights.canPublish==1" type="success" icon="el-icon-finished" size="mini" @click="publish(scope.row.id)">发布</el-button>
          <el-button v-if="scope.row.publish_status==2 && rights.canPublish==1" type="warning" icon="el-icon-finished" size="mini" @click="unPublish(scope.row.id)">取消</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="param.count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <category-add :visible="addDialogAvaible"
      :id="currentId"
      :groupList="groupList"
      :columnList="columnList"
      @add="addCategory"
      @update="updateCategory"
      @close="closeAddDialog">
    </category-add>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import CategoryAdd from './CategoryAdd.vue'
export default {
  name: 'category_list',
  components: {
    'bread-crumb': BreadCrumb,
    'category-add': CategoryAdd
  },
  data () {
    return {
      groupList: [],
      columnList: [],
      rights: {
        canAdd: 0,
        canUpdate: 0,
        canDelete: 0,
        canPublish: 0
      },
      categoryList: [],
      currentId: 0,
      total: 0,
      pageTotal: 1,
      param: {
        group_id: '',
        column_id: '',
        is_hot: '',
        is_publish: '',
        keywords: '',
        page: 1,
        count: 10
      },
      addDialogAvaible: false
    }
  },
  created () {
    this.getGroupList()
    this.getColumnList()
    this.getCategoryList()
    const rightArr = window.sessionStorage.getItem('rights').split(',')
    if (rightArr.indexOf('12') !== -1) {
      this.rights.canAdd = 1
    }
    if (rightArr.indexOf('13') !== -1) {
      this.rights.canUpdate = 1
    }
    if (rightArr.indexOf('14') !== -1) {
      this.rights.canDelete = 1
    }
    if (rightArr.indexOf('11') !== -1) {
      this.rights.canPublish = 1
    }
  },
  methods: {
    async getCategoryList () {
      const res = await this.$http.get('/admin/category/list', this.param)
      if (res.code === 0) {
        this.categoryList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
    },
    async getGroupList () {
      const res = await this.$http.get('/admin/category/group/select_list')
      if (res.code === 0) {
        this.groupList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getColumnList () {
      const res = await this.$http.get('/admin/column/select_list')
      if (res.code === 0) {
        this.columnList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    search () {
      this.param.page = 1
      this.getCategoryList()
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getCategoryList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getCategoryList()
    },
    showAddDialog () {
      this.addDialogAvaible = true
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.currentId = id
      this.showAddDialog()
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/category/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getCategoryList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    },
    async addCategory (data) {
      const res = await this.$http.post('/admin/category/add', data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getCategoryList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateCategory (id, data) {
      const res = await this.$http.post('/admin/category/update/' + id, data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getCategoryList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async publish (id) {
      const res = await this.$http.post('/admin/category/publish/' + id)
      if (res.code === 0) {
        this.$message.success('发布成功')
        this.getCategoryList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async unPublish (id) {
      const res = await this.$http.post('/admin/category/un_publish/' + id)
      if (res.code === 0) {
        this.$message.success('取消成功')
        this.getCategoryList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
</style>
