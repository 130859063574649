<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="67%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="categoryFormRules" ref="categoryFormRef" label-width="80px">
      <el-form-item label="名称" prop="name">
        <el-input v-model="formData.name" style="width: 30%;"></el-input>
      </el-form-item>
      <el-form-item label="封面图" prop="pic">
        <template>
          <el-upload
            class="avatar-uploader"
            action="/proxy/admin/upload_img"
            name="img"
            :show-file-list="false"
            :on-success="handleSuccess">
            <img v-if="picUrl" :src="picUrl" class="cover">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-input v-model="formData.pic" style="width: 30%;"></el-input>
        </template>
      </el-form-item>
      <el-form-item label="分类" prop="groupId">
        <el-select v-model="formData.groupId" placeholder="请选择分类" filterable>
          <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专栏" prop="columnId">
        <el-select v-model="formData.columnId" placeholder="请选择专栏" filterable>
          <el-option label="请选择专栏" :value="0"></el-option>
          <el-option v-for="item in columnList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否推荐" prop="isHot">
        <el-radio v-model="formData.isHot" :label="1">是</el-radio>
        <el-radio v-model="formData.isHot" :label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="排序值" prop="sort">
        <el-input v-model="formData.sort" style="width: 30%;"></el-input>
      </el-form-item>
      <el-form-item label="内容排序" prop="contentSort">
        <el-radio v-model="formData.contentSort" :label="1">正序</el-radio>
        <el-radio v-model="formData.contentSort" :label="0">倒序</el-radio>
      </el-form-item>
      <el-form-item label="简介" prop="intro">
        <el-input v-model="formData.intro" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'category_add',
  props: [
    'visible', 'id', 'groupList', 'columnList'
  ],
  components: {
  },
  data () {
    return {
      title: '添加专辑',
      formData: {
        name: '',
        pic: '',
        groupId: '',
        columnId: 0,
        isHot: 0,
        sort: 0,
        contentSort: 0,
        intro: ''
      },
      // picUrl: '',
      categoryFormRules: {
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
        ],
        pic: [
          { required: true, message: '头像不能为空', trigger: 'blur' }
        ],
        groupId: [
          { required: true, message: '栏目不能为空', trigger: 'blur' }
        ],
        intro: [
          { required: true, message: '介绍不能为空', trigger: 'blur' },
          { min: 2, max: 500, message: '长度在 2 到 500 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.categoryFormRef.resetFields()
      // this.picUrl = ''
      this.$emit('close')
    },
    addConfirm () {
      this.$refs.categoryFormRef.validate(valid => {
        if (!valid) return
        // 验证通过
        // this.picUrl = ''
        if (this.id === 0) {
          this.$emit('add', this.formData)
        } else {
          this.$emit('update', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑专辑'
        this.getInfo(this.id)
      }
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/category/info/' + id)
      if (res.code === 0) {
        this.formData = {
          name: res.data.name,
          pic: res.data.pic,
          groupId: res.data.group_id,
          columnId: res.data.column_id,
          isHot: res.data.is_hot,
          intro: res.data.intro,
          sort: res.data.sort,
          contentSort: res.data.content_sort
        }
        // this.picUrl = res.data.pic_url
      }
    },
    handleSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        // this.picUrl = resp.data.url
        this.formData.pic = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    }
  },
  computed: {
    picUrl () {
      if (this.formData.pic !== '') {
        return 'https://res.foregoer.hnsaint.com/' + this.formData.pic
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.cover {
  width: 50px;
  display: block;
}
</style>
