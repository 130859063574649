5ewq<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="30%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="80px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="封面" prop="pic">
        <template>
          <el-upload
            class="avatar-uploader"
            action="/proxy/admin/upload_img"
            name="img"
            :show-file-list="false"
            :on-success="handleSuccess">
            <img v-if="imageUrl" :src="imageUrl" class="cover">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </el-form-item>
      <el-form-item label="排序值" prop="sort">
        <el-input v-model="formData.sort"></el-input>
      </el-form-item>
      <el-form-item label="链接" prop="url">
        <el-input v-model="formData.url" style="width:60%;margin-right:10px"></el-input>
        <el-button @click="showUrlDialog" type="primary" size="mini">添加链接</el-button>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
    <url-add :visible="urlDialogVisible" :id="id" @closeDialog="closeUrlDialog" @getUrl="getUrl"></url-add>
  </el-dialog>
</template>

<script>
import Url from './Url.vue'
export default {
  name: 'carousel_add',
  props: [
    'visible', 'id'
  ],
  components: {
    'url-add': Url
  },
  data () {
    return {
      title: '添加轮播',
      formData: {
        title: '',
        pic: '',
        group_id: 0,
        category_id: 0,
        content_id: 0,
        sort: 0,
        url: ''
      },
      imageUrl: '',
      urlDialogVisible: false,
      formRules: {
        title: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
        ],
        pic: [
          { required: true, message: '图标不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.formRef.resetFields()
      this.imageUrl = ''
      this.$emit('closeDialog')
    },
    showUrlDialog () {
      this.urlDialogVisible = true
    },
    closeUrlDialog () {
      this.urlDialogVisible = false
    },
    addConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.imageUrl = ''
        if (this.id === 0) {
          this.$emit('add', this.formData)
        } else {
          this.$emit('update', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑轮播'
        this.getInfo(this.id)
      }
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/carousel/info/' + id)
      if (res.code === 0) {
        this.formData = {
          title: res.data.title,
          pic: res.data.pic,
          group_id: res.data.group_id,
          category_id: res.data.category_id,
          content_id: res.data.content_id,
          url: res.data.url,
          sort: res.data.sort
        }
        this.imageUrl = res.data.pic_url
      }
    },
    handleSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.imageUrl = resp.data.url
        this.formData.pic = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    },
    getUrl (data) {
      this.formData.group_id = data.group_id
      this.formData.category_id = data.category_id
      this.formData.content_id = data.content_id
      this.formData.url = data.url
      this.closeUrlDialog()
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.cover {
  width: 50px;
  display: block;
}
</style>
