<template>
  <el-dialog :title="title" :visible.sync="visible" :before-close="closeDialog" @closed="closeDialog" width="50%" @open="addOpen" :close-on-click-modal="false">
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="100px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item label="分类" prop="group_id">
        <el-select v-model="formData.group_id" placeholder="请选择分类" filterable @change="getCategoryList">
          <el-option label="请选择分类" :value="0"></el-option>
          <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专辑" prop="category_id">
        <el-select v-model="formData.category_id" placeholder="请选择专辑名称" filterable>
          <el-option label="请选择专辑" :value="0"></el-option>
          <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="封面图" prop="cover">
        <template>
          <el-upload
            class="avatar-uploader"
            action="/proxy/admin/upload_img"
            name="img"
            :show-file-list="false"
            :on-success="handleCoverSuccess">
            <img v-if="coverUrl" :src="coverUrl" class="cover">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </template>
      </el-form-item>
      <el-form-item label="类型" prop="content_type">
        <el-select v-model="formData.content_type" placeholder="请选择内容类型" filterable>
          <el-option label="视频" :value="3"></el-option>
          <el-option label="音频" :value="2"></el-option>
          <el-option label="图文" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="formData.content_type==1" label="内容" prop="content">
        <el-upload :show-upload-list="false"
          :on-success="handleQuillSuccess"
          :format="['jpg','jpeg','png','gif']"
          :max-size="2048" multiple
          action="/proxy/admin/upload_img"
          name="img"
          style="display: none;">
          <el-button id="quill_upload" icon="ios-cloud-upload-outline" ></el-button>
        </el-upload>
        <quill-editor ref="quillEditorRef" v-model="formData.content" :options="editorOption" />
      </el-form-item>
      <el-form-item v-else label="音频/视频" prop="media_url">
        <el-upload
          name="media"
          action="/proxy/admin/upload_media"
          :on-success="handleSuccess"
          :file-list="mediaFileList">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <el-input v-model="formData.media_url"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import { ImageDrop } from 'quill-image-drop-module'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageDrop', ImageDrop)
Quill.register('modules/imageResize', ImageResize)

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ indent: '-1' }, { indent: '+1' }],
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ['link', 'image', 'video'],
  ['sourceEditor']
]

export default {
  name: 'content_add',
  props: [
    'visible', 'id', 'groupList'
  ],
  components: {
    'quill-editor': quillEditor
  },
  data () {
    return {
      mediaFileList: [],
      title: '添加内容',
      categoryList: [],
      formData: {
        title: '',
        group_id: 0,
        category_id: 0,
        cover: '',
        content_type: 3,
        content: '',
        media_url: ''
      },
      coverUrl: '',
      editorOption: {
        placeholder: '编辑配置内容',
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image (value) {
                if (value) {
                  document.querySelector('#quill_upload').click()
                }
              },
              video (value) {
                if (value) {
                  document.querySelector('#quill_upload_media').click()
                }
              }
            }
          },
          imageDrop: true,
          imageResize: {
            displayStyles: {
              backgroundColor: 'black',
              border: 'none',
              color: 'white'
            },
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          }
        }
      },
      picUrl: '',
      formRules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        group_id: [
          { required: true, message: '栏目不能为空', trigger: 'blur' }
        ],
        category_id: [
          { required: true, message: '专辑不能为空', trigger: 'blur' }
        ],
        content_type: [
          { required: true, message: '内容类型不能为空', trigger: 'blur' }
        ],
        media_url: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.formRef.resetFields()
      this.mediaFileList = []
      this.coverUrl = ''
      this.$emit('close')
    },
    addConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        if (this.id === 0) {
          this.$emit('add', this.formData)
        } else {
          this.$emit('update', this.id, this.formData)
        }
      })
    },
    addOpen () {
      // 如果id>0,则是编辑信息
      if (this.id > 0) {
        this.title = '编辑内容'
        this.getInfo(this.id)
      }
    },
    async getCategoryList () {
      if (this.formData.group_id === 0 || this.formData.speaker_id === 0) {
        this.categoryList = []
        this.formData.category_id = 0
        return
      }
      const res = await this.$http.get('/admin/category/select_list', {
        group_id: this.formData.group_id
      })
      if (res.code === 0) {
        this.categoryList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/content/info/' + id)
      if (res.code === 0) {
        this.formData = {
          title: res.data.title,
          group_id: res.data.group_id,
          category_id: res.data.category_id,
          cover: res.data.cover,
          content_type: res.data.content_type,
          content: res.data.content,
          media_url: res.data.media_url
        }
        this.getCategoryList()
        this.mediaFileList = []
        this.coverUrl = res.data.cover_url
      }
    },
    handleSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.mediaFileList = []
        this.formData.media_url = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    },
    handleCoverSuccess (resp, file, fileList) {
      if (resp.code === 0) {
        this.coverUrl = resp.data.url
        this.formData.cover = resp.data.path
      } else {
        this.$message.error(resp.msg)
      }
    },
    handleQuillSuccess (res) {
      // 获取富文本组件实例
      const quill = this.$refs.quillEditorRef.quill
      // 如果上传成功
      if (res) {
        // 获取光标所在位置
        const length = quill.getSelection().index
        // 插入图片，res为服务器返回的图片链接地址
        quill.insertEmbed(length, 'image', res.data.url)
        // 调整光标到最后
        quill.setSelection(length + 1)
      } else {
        // 提示信息，需引入Message
        console.log('图片插入失败')
      }
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.cover {
  width: 50px;
  display: block;
}
</style>
