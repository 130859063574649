<template>
  <el-dialog :title="title" :visible.sync="visible" :modal="false" :before-close="closeDialog" @closed="closeDialog" width="20%" @open="dialogOpen">
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="80px">
      <el-form-item label="栏目" prop="group_id">
        <el-select v-model="formData.group_id" placeholder="请选择栏目" filterable @change="getCategoryList">
          <el-option label="请选择栏目" :value="0"></el-option>
          <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专辑" prop="category_id">
        <el-select v-model="formData.category_id" placeholder="请选择专辑名称" filterable @change="getContentList">
          <el-option label="请选择专辑" :value="0"></el-option>
          <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="内容" prop="content_id">
        <el-select v-model="formData.content_id" placeholder="请选择内容" filterable>
          <el-option label="请选择内容" :value="0"></el-option>
          <el-option v-for="item in contentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="addConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'carousel_add',
  props: [
    'visible', 'id'
  ],
  data () {
    return {
      title: '添加轮播链接',
      groupList: [],
      categoryList: [],
      contentList: [],
      formData: {
        group_id: 0,
        category_id: 0,
        content_id: 0,
        url: ''
      },
      formRules: {
        group_id: [
          { required: true, message: '栏目不能为空', trigger: 'blur' }
        ],
        category_id: [
          { required: true, message: '专辑不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$refs.formRef.resetFields()
      this.$emit('closeDialog')
    },
    addConfirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.formData.url = '../../pages/album/detail?albumId=' + this.formData.category_id
        if (this.formData.content_id > 0) {
          this.formData.url = this.formData.url + '&contentId=' + this.formData.content_id
        }
        this.$emit('getUrl', this.formData)
      })
    },
    dialogOpen () {
      this.getGroupList()
      if (this.id > 0) {
        this.getInfo(this.id)
      }
    },
    async getInfo (id) {
      const res = await this.$http.get('/admin/carousel/info/' + id)
      if (res.code === 0) {
        this.formData.group_id = res.data.group_id
        this.formData.category_id = res.data.category_id
        this.formData.content_id = res.data.content_id
        this.getCategoryList()
        this.getContentList()
      }
    },
    async getGroupList () {
      const res = await this.$http.get('/admin/category/group/select_list')
      if (res.code === 0) {
        this.groupList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getCategoryList () {
      if (this.formData.group_id === 0) {
        this.categoryList = []
        this.formData.category_id = 0
        return
      }
      const res = await this.$http.get('/admin/category/select_list', this.formData)
      if (res.code === 0) {
        this.categoryList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getContentList () {
      if (this.formData.category_id === 0) {
        this.contentList = []
        this.formData.content_id = 0
        return
      }
      const res = await this.$http.get('/admin/content/select_list', this.formData)
      if (res.code === 0) {
        this.contentList = res.data
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style>
</style>
