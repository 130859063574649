<template>
  <el-dialog
    title="内容推荐"
    :visible.sync="visible"
    width="30%"
    @close="closeDialog"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false">
    <el-form :model="formData" :rules="formRules" ref="formRef" label-width="120px">
      <el-form-item label="推荐开始时间" prop="recommend_start">
        <el-date-picker
          v-model="formData.recommend_start"
          type="datetime"
          value-format="timestamp"
          placeholder="开始时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="推荐结束时间" prop="recommend_end">
        <el-date-picker
          v-model="formData.recommend_end"
          type="datetime"
          value-format="timestamp"
          placeholder="结束时间空为一直推荐"></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'content_recommend',
  props: [
    'visible', 'id'
  ],
  data () {
    return {
      formData: {
        recommend_start: '',
        recommend_end: ''
      },
      formRules: {
        recommend_start: [
          { required: true, message: '推荐时间不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeRecommend')
      this.$refs.formRef.resetFields()
    },
    confirm () {
      this.$refs.formRef.validate(valid => {
        if (!valid) return
        // 验证通过
        this.$emit('recommend', this.id, this.formData.recommend_start, this.formData.recommend_end)
      })
    }
  }
}
</script>

<style>
</style>
