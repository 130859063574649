<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb path='内容管理' subPath='内容列表'></bread-crumb>
    <!-- 主体列表 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-button type="primary" @click="showAddDialog">添加内容</el-button>
        </el-col>
        <el-col :span="4">
          <el-select v-model="param.group_id" placeholder="请选择分类" filterable @change="getCategoryList">
            <el-option label="请选择分类" :value="0"></el-option>
            <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="param.category_id" placeholder="请选择专辑" filterable>
            <el-option label="请选择专辑" :value="0"></el-option>
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="param.content_type" placeholder="请选择类型" filterable>
            <el-option label="请选择类型" :value="0"></el-option>
            <el-option label="视频" :value="3"></el-option>
            <el-option label="音频" :value="2"></el-option>
            <el-option label="图文" :value="1"></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <el-input placeholder="请输入标题" v-model="param.keywords" clearable @clear="search">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="param.count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <el-table :data="contentList" stripe border>
      <el-table-column prop="id" label="ID" align="center" width="60px"></el-table-column>
      <el-table-column prop="title" label="标题" align="left" header-align="center"></el-table-column>
      <el-table-column prop="group_name" label="分类" align="center"></el-table-column>
      <el-table-column prop="category_name" label="专辑" align="center"></el-table-column>
      <el-table-column prop="cover" label="封面图" align="center">
        <template slot-scope="scope">
          <el-image v-if="scope.row.cover" :src="scope.row.cover" class="list_img"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="是否推荐" align="center">
        <template slot-scope="scope">
          <el-switch :value="scope.row.recommend_status==1"></el-switch>
          <div v-if="scope.row.recommend_status==1">{{scope.row.recommend_start | dateTimeFormat}}</div>
          <div v-if="scope.row.recommend_status==1">
            <span v-if="scope.row.recommend_end===0">一直推荐</span>
            <span v-else-if="scope.row.recommend_end<=nowTime" style="color: #ff0000;">{{scope.row.recommend_end | dateTimeFormat}}</span>
            <span v-else>{{scope.row.recommend_end | dateTimeFormat}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="play_times" label="浏览次数" align="center"></el-table-column>
      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          {{scope.row.create_time | dateTimeFormat}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="290px">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button v-if="scope.row.recommend_status==0" type="success" icon="el-icon-finished" size="mini" @click="showRecommendDialog(scope.row.id)">推荐</el-button>
          <el-tooltip v-if="scope.row.recommend_status==1" effect="dark" content="取消推荐" placement="top" :enterable="false">
            <el-button  type="warning" icon="el-icon-finished" size="mini" @click="unRecommend(scope.row.id)">取消</el-button>
          </el-tooltip>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="param.page"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="param.count"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <content-add :visible="addDialogAvaible"
      :id="currentId"
      :groupList="groupList"
      @add="addContent"
      @update="updateContent"
      @close="closeAddDialog">
    </content-add>
    <content-recommend :visible="recommendAvaible"
      :id="recommendId"
      @recommend="recommend"
      @closeRecommend="closeRecommendDialog">
    </content-recommend>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import ContentAdd from './contentAdd.vue'
import ContentRecommend from './contentRecommend.vue'
export default {
  name: 'content_list',
  components: {
    'bread-crumb': BreadCrumb,
    'content-add': ContentAdd,
    'content-recommend': ContentRecommend
  },
  data () {
    return {
      groupList: [],
      categoryList: [],
      contentList: [],
      currentId: 0,
      total: 0,
      pageTotal: 1,
      param: {
        group_id: 0,
        category_id: 0,
        content_type: 0,
        keywords: '',
        page: 1,
        count: 10
      },
      nowTime: 0,
      addDialogAvaible: false,
      recommendAvaible: false,
      recommendId: 0
    }
  },
  created () {
    this.getGroupList()
    this.getContentList()
  },
  methods: {
    async getGroupList () {
      const res = await this.$http.get('/admin/category/group/select_list')
      if (res.code === 0) {
        this.groupList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getCategoryList () {
      if (this.param.group_id === 0) {
        this.categoryList = []
        this.param.category_id = 0
        return
      }
      const res = await this.$http.get('/admin/category/select_list', {
        group_id: this.param.group_id,
        speaker_id: this.param.speaker_id
      })
      if (res.code === 0) {
        this.categoryList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async getContentList () {
      const res = await this.$http.get('/admin/content/list', this.param)
      if (res.code === 0) {
        this.contentList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
        this.nowTime = res.data.now_time
      } else {
        this.$message.error(res.msg)
      }
    },
    search () {
      this.param.page = 1
      this.getContentList()
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getContentList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getContentList()
    },
    showAddDialog () {
      this.addDialogAvaible = true
    },
    closeAddDialog () {
      this.addDialogAvaible = false
      this.currentId = 0
    },
    showEditDialog (id) {
      this.currentId = id
      this.showAddDialog()
    },
    deleteRow (id) {
      this.$confirm('是否真的要删除此记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/content/delete/' + id)
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getContentList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    },
    async addContent (data) {
      const res = await this.$http.post('/admin/content/add', data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getContentList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async updateContent (id, data) {
      const res = await this.$http.post('/admin/content/update/' + id, data)
      if (res.code === 0) {
        this.closeAddDialog()
        this.getContentList()
      } else {
        this.$message.error(res.msg)
      }
    },
    showRecommendDialog (id) {
      this.recommendId = id
      this.recommendAvaible = true
    },
    closeRecommendDialog () {
      this.recommendAvaible = false
      this.recommendId = 0
    },
    async recommend (id, recommendStart, recommendEnd) {
      const data = {
        recommend_start: recommendStart,
        recommend_end: recommendEnd
      }
      const res = await this.$http.post('/admin/content/recommend/' + id, data)
      if (res.code === 0) {
        this.$message.success('推荐成功')
        this.getContentList()
      } else {
        this.$message.error(res.msg)
      }
      this.closeRecommendDialog()
    },
    async unRecommend (id) {
      this.$confirm('真的要取消推荐吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await this.$http.post('/admin/content/un_recommend/' + id)
        if (res.code === 0) {
          this.$message.success('取消成功')
          this.getContentList()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.list_img {
  width: 50px;
}
</style>
