<template>
  <!-- 侧边栏菜单区域 -->
  <el-menu background-color="#172e69"
    text-color="#FFFFFF"
    :unique-opened="true"
    :collapse="collapse"
    :collapse-transition="false"
    :router="true"
    :default-active="activeIndex"
    active-text-color="#409EFF">
    <!-- 一级菜单 -->
    <el-submenu :index="item.id+''" v-for="item in menuList" :key="item.id">
      <template slot="title">
        <i :class="item.icon"></i>
        <span class="menu_title">{{item.name}}</span>
      </template>
      <!-- 二级菜单 -->
      <el-menu-item :index="subItem.path" v-for="subItem in item.child_list" :key="subItem.id">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span class="sub_menu_title" @click="changeActiveIndex(subItem.path)">{{subItem.name}}</span>
        </template>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  name: 'home_menu',
  props: [
    'menuList', 'collapse', 'activeIndex'
  ],
  methods: {
    changeActiveIndex (index) {
      this.$emit('changeActiveIndex', index)
    }
  }
}
</script>

<style scoped>
.menu_title {
  font-size: 16px;
}

.el-menu {
  border-right: none;
}

.iconfont {
  margin-right: 8px;
  color: #FFFFFF;
}

.sub_menu_title {
  font-size: 14px;
}
.el-icon-menu {
  color: #FFFFFF;
}
</style>
