<template>
  <div>
    <bread-crumb path='用户管理' subPath='管理员列表'></bread-crumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button type="primary" @click="addAdmin">添加管理员</el-button>
        </el-col>
      </el-row>
      <el-table :data="adminList" stripe border :cell-style="{ textAlign: 'center' }">
        <el-table-column prop="id" label="ID" align="center"></el-table-column>
        <el-table-column prop="name" label="用户名" align="center"></el-table-column>
        <el-table-column prop="nick_name" label="昵称" align="center"></el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <el-image v-if="scope.row.avatar!=''" :src="scope.row.avatar" class="list_img"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="role_name" label="角色" align="center"></el-table-column>
        <el-table-column prop="create_time_str" label="创建时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="175px">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="修改" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="查看详情" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-document" size="mini"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteAdmin(scope.row.id)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="param.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="param.count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <admin-add :id="id"
        :addVisible="addVisible"
        :roleList="roleList"
        @closeDialog="closeDialog"
        @updateAdmin="updateAdminPost"
        @addAdmin="addAdminPost">
      </admin-add>
    </el-card>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumb.vue'
import AdminAdd from './AdminAdd.vue'
export default {
  name: 'admin_list',
  components: {
    'bread-crumb': BreadCrumb,
    'admin-add': AdminAdd
  },
  created () {
    this.getAdminList()
    this.getRoleList()
  },
  data () {
    return {
      adminList: [],
      roleList: [],
      total: 0,
      pageTotal: 1,
      param: {
        page: 1,
        count: 10
      },
      addVisible: false,
      addForm: {
        name: ''
      },
      id: 0
    }
  },
  methods: {
    async getAdminList () {
      const res = await this.$http.get('/admin/list', this.param)
      if (res.code === 0) {
        this.adminList = res.data.list
        this.total = res.data.total
        this.pageTotal = res.data.total_page
      } else {
        this.$message.error(res.msg)
      }
    },
    async getRoleList () {
      const res = await this.$http.get('/admin/role/select_list')
      if (res.code === 0) {
        this.roleList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    handleSizeChange (size) {
      this.param.count = size
      this.getAdminList()
    },
    handleCurrentChange (page) {
      this.param.page = page
      this.getAdminList()
    },
    showEditDialog (id) {
      this.id = id
      this.openDialog()
    },
    openDialog () {
      this.addVisible = true
    },
    closeDialog () {
      this.addVisible = false
      this.id = 0
    },
    deleteAdmin (id) {},
    addAdmin () {
      this.openDialog()
    },
    async updateAdminPost (id, data) {
      const res = await this.$http.post('/admin/update/' + id, data)
      if (res.code === 0) {
        this.closeDialog()
        this.getAdminList()
      } else {
        this.$message.error(res.msg)
      }
    },
    async addAdminPost (data) {
      const res = await this.$http.post('/admin/add', data)
      if (res.code === 0) {
        this.closeDialog()
        this.getAdminList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style>
</style>
