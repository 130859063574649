import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import AdminList from '../components/admin/AdminList.vue'
import RightList from '../components/right/RightList.vue'
import RoleList from '../components/role/RoleList.vue'
import CategoryGroupList from '../components/category_group/GroupList.vue'
import ColumnList from '../components/column/ColumnList'
import CategoryList from '../components/category_group/CategoryList.vue'
import ContentList from '../components/content/contentList.vue'
import CarouselList from '../components/carousel/CarouselList.vue'
import ConfigList from '../components/config/ConfigList.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/admin/list', component: AdminList },
      { path: '/right/list', component: RightList },
      { path: '/role/list', component: RoleList },
      { path: '/category_group/list', component: CategoryGroupList },
      { path: '/column/list', component: ColumnList },
      { path: '/category/list', component: CategoryList },
      { path: '/content/list', component: ContentList },
      { path: '/carousel/list', component: CarouselList },
      { path: '/config/list', component: ConfigList }
    ]
  }
]

const router = new VueRouter({
  routes
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  const token = window.sessionStorage.getItem('token')
  if (!token) return next('/login')
  next()
})

export default router
